import React from 'react';
import { Box, Text, HStack, VStack, Heading, Flex, Button } from '@chakra-ui/react';
import { MdQuestionAnswer } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const faqList = [
    {
        pergunta: "Recebi o email com as credenciais, mas não estou conseguindo logar:",
        resposta: "Solicite uma nova senha logo abaixo do login. Caso você não consiga logar com essa nova senha, entre em contato com oecc@dee.ufcg.edu.br."
    },
    {
        pergunta: "Comecei a prova e as questões não carregaram ou apareceu um erro",
        resposta: "Atualize a página e veja se funciona. Caso não funcione, entre em contato com oecc@dee.ufcg.edu.br."
    },
    {
        pergunta: "Finalizei a prova e apareceu um erro:",
        resposta: "Caso você tenha recebido o email de prova finalizada, sua prova foi recebida pelo sistema. Caso você não tenha recebido o email, entre em contato com oecc@dee.ufcg.edu.br."
    },
    {
        pergunta: "Comecei a prova e minha internet caiu:",
        resposta: "É possível que seu navegador continue carregando a prova. Porém, o seu progresso não tem garantia de estar salvo. Indicamos esperar até ter estabilidade de internet para recarregar a prova e voltar a responde-la."
    },
];

const FAQ = () => {
    const navigate = useNavigate();

    const handleBackToHome = () => {
        navigate('/home'); 
    };
    return (
        <Box bgColor={'#f0f0f0'} minHeight="100vh" p={8}>
            <Flex display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Heading textAlign="center" fontSize={{ base: '30px', md: '34px', lg: '46px' }} color="#323C33" mb={6}>
                    Dúvidas Frequentes
                </Heading>
                <VStack align="start" spacing={4} maxW="800px" w="100%" mb={6}>
                    {faqList.map((item, index) => (
                        <HStack key={index} alignItems="flex-start">
                            <Box as={MdQuestionAnswer} size="24px" color="#F5811E" />
                            <Box flex="1" fontSize="18px">
                                <Text fontWeight="bold" align="justify">
                                    {item.pergunta}
                                </Text>
                                <Text align="justify">
                                    {item.resposta}
                                </Text>
                            </Box>
                        </HStack>
                    ))}
                </VStack>
                <Button onClick={handleBackToHome} colorScheme="orange" variant="solid">
                    Voltar
                </Button>
            </Flex>
        </Box>
    );
};

export default FAQ;
