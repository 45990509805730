import axios from 'axios';

export const handleLogin = async (email, senha, login, setError, setIsAdmin) => {
    setError(null);
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
        setError("E-mail inválido. Por favor, insira um e-mail válido.");
        return;
    }

    try {
        const response = await axios.post("https://ocee.com.br/usuarios/login", { email, senha });
        if (response.status === 200) {
            const { estado_prova, nome, is_admin } = response.data;
            setIsAdmin(is_admin);

            // Armazena o estado da prova e o email no localStorage
            localStorage.setItem('email', email);
            localStorage.setItem('estado_prova', estado_prova);
            localStorage.setItem('nome', nome);
            localStorage.setItem('is_admin', is_admin);

            // Chama a função login passando o estado da prova
            login(estado_prova, nome, is_admin);

        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            setError("Problemas de login: email ou senha incorretos");
        } else {
            setError("Erro desconhecido");
        }
    }
};

export const handlePerdeuSenha = async (email, setError, onClose) => {
    setError(null);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        setError("E-mail inválido. Por favor, insira um e-mail válido.");
        return false; // Indicar que a operação falhou devido ao e-mail inválido
    }

    try {
        const response = await axios.post("https://ocee.com.br/usuarios/resetar-senha/", { email });

        if (response.status === 200) {
            onClose(); // Fechar o modal após solicitar a redefinição de senha
            return true; // Indicar que a operação foi bem-sucedida
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            setError("Erro desconhecido");
        } else {
            setError("Erro ao solicitar redefinição de senha");
        }
        return false; // Indicar que a operação falhou
    }
    return false; // Indicar que a operação falhou por outro motivo
};
