import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Menu from '../Menu/Menu';
import VideoModal from '../Tutorial/VideoModal';
import HomeContent from './HomeContent';
import TutorialModal from '../Tutorial/TutorialModal';
import { Box, useDisclosure } from '@chakra-ui/react';
import ContinuarProvaModal from './ContinuarProvaModal';

const Home = () => {
    const email = localStorage.getItem("email");
    const estadoProva = localStorage.getItem("estado_prova"); // Pega o estado da prova do localStorage
    console.log(estadoProva);

    const navigate = useNavigate(); // Usar useNavigate para a navegação
    const [provaIncompleta, setProvaIncompleta] = useState(true); // Adicione este estado para verificar se a prova está incompleta
    const [questoesRestantes, setQuestoesRestantes] = useState(5); // Substitua pelo valor real
    const [tempoRestante, setTempoRestante] = useState(30); // Substitua pelo valor real

    // Gerencia o estado do modal
    const { isOpen: isVideoOpen, onOpen: onVideoOpen, onClose: onVideoClose } = useDisclosure();
    const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
    const { isOpen: isProvaContinuidadeOpen, onOpen: onProvaContinuidadeOpen, onClose: onProvaContinuidadeClose } = useDisclosure();

    const handleProva = async () => {
        const getUrl = `https://ocee.com.br/usuarios/estado-tutorial/recuperar/?email=${email}`;
        const response = await axios.get(getUrl);
        const estadoTutorial = response.data.estado_tutorial;

        if (estadoTutorial === "Assistido" || estadoTutorial === "assistido") {
            // Verifica se o estado da prova é "em andamento" para abrir o modal de continuar prova
            if (estadoProva === "em andamento") {
                onProvaContinuidadeOpen(); // Abre o modal de continuidade da prova
            } else {
                navigate('/prova'); // Navega diretamente para a prova se o estado não for "em andamento"
            }
        } else {
            onAlertOpen(); // Mostra o pop-up se o tutorial não foi acessado
        }
    };

    const handleTutorial = async () => {
        onVideoOpen(); // Abre o modal de vídeo
        const setarUrl = 'https://ocee.com.br/usuarios/estado-tutorial/setar/';
        const data = {
            email: email,
            estado_tutorial: 'assistido'
        };

        await axios.put(setarUrl, data);
        console.log('Estado do tutorial atualizado para "assistido"');
        console.log(email);
    };

    const handleContinuarProva = () => {
        onProvaContinuidadeClose();
        navigate('/prova'); // Navega para a prova quando o aluno escolhe continuar
    };

    return (
        <Box bgColor={'#f0f0f0'} height="100%">
            <Menu />
            <HomeContent handleProva={handleProva} handleTutorial={handleTutorial} />
            <VideoModal isOpen={isVideoOpen} onClose={onVideoClose} />
            <TutorialModal isOpen={isAlertOpen} onClose={onAlertClose} />
            <ContinuarProvaModal
                isOpen={isProvaContinuidadeOpen}
                onClose={onProvaContinuidadeClose}
                questoesRestantes={questoesRestantes}
                tempoRestante={tempoRestante}
                onContinuar={handleContinuarProva}
            />
        </Box>
    );
};

export default Home;
