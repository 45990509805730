import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from '@chakra-ui/react';

const ConfirmarEnvio = ({ isOpen, onClose, onConfirm }) => {
    const [isSending, setIsSending] = useState(false);

    const handleConfirmClick = () => {
        setIsSending(true); // Desativa o botão após o clique
        onConfirm(); // Chama a função de confirmação passada via props
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent margin='4'>
                <ModalHeader>Confirmar Envio</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Tem certeza de que deseja enviar a prova?
                </ModalBody>
                <ModalFooter>
                    <Button 
                        colorScheme="blue" 
                        mr={3} 
                        onClick={handleConfirmClick} 
                        isLoading={isSending} // Mostra um spinner enquanto envia
                        isDisabled={isSending} // Desativa o botão após o clique
                    >
                        Enviar
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancelar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmarEnvio;
