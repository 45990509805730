import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Box, Button, HStack, Heading, Image, VStack, Text } from '@chakra-ui/react';
import Regras from './Regras';

const HomeContent = ({ handleProva, handleTutorial }) => {
    // Defina a data e hora de início da prova
    const startDate = new Date('2024-11-17T00:00:00'); 
    const [isProvaEnabled, setIsProvaEnabled] = useState(false);

    useEffect(() => {
        // Função que verifica se a data atual é igual ou após a data de início
        const checkStartDate = () => {
            const now = new Date();
            setIsProvaEnabled(now >= startDate);
        };

        checkStartDate();

        // Configura um intervalo para verificar a cada minuto (opcional)
        const intervalId = setInterval(checkStartDate, 60000);
        
        return () => clearInterval(intervalId); // Limpa o intervalo quando o componente desmonta
    }, [startDate]);

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height={{ base: "180vh", md: '120vh', lg: '100vh' }} p={[8, 12]} bgColor={'#f0f0f0'} flexDirection={'column'}>
            <Heading textAlign="center" fontSize={{ base: '30px', md: '34px', lg: '46px' }} color="#323C33">
                Seja bem-vindo(a) à Olimpíada Educacional do Consumo Consciente
            </Heading>
            <HStack gap={10} justify="center" flexDirection={{ base: 'column', lg: 'row' }} alignItems="center" mt={'8'}>
                <Image
                    loading="lazy"
                    src="/imagens/Online test-amico.svg"
                    w={{ base: '100%', md: '50%', lg: '30%' }}
                />
                <VStack spacing={8} align="center" w={{ base: '100%', md: '80%', lg: '60%' }}>
                    <Regras />
                    <HStack
                        spacing={{ base: 1, md: 8 }}
                        flexDirection={{ base: 'column', md: 'row' }}
                        w="100%"
                        justify="center"
                    >
                        <Button
                            bg="#F5811E"
                            color="white"
                            borderRadius="40px"
                            mt={{ base: 4, lg: 2 }}
                            _hover={{ bgGradient: "linear(to-r, orange.300, orange.500)" }}
                            w={'20em'}
                            onClick={handleProva}
                            isDisabled={!isProvaEnabled} // Desabilita o botão se isProvaEnabled for false
                        >
                            {isProvaEnabled ? "Iniciar prova" : "Prova disponível em breve"}
                        </Button>
                        <Button
                            bg="#F5811E"
                            color="white"
                            borderRadius="40px"
                            mt={{ base: 4, lg: 2 }}
                            _hover={{ bgGradient: "linear(to-r, orange.300, orange.500)" }}
                            w={'20em'}
                            onClick={handleTutorial}
                        >
                            Iniciar Tutorial
                        </Button>
                    </HStack>
                    {!isProvaEnabled && (
                        <Text color="gray.500" fontSize="sm">
                            A prova estará disponível em {startDate.toLocaleDateString()} às {startDate.toLocaleTimeString()}.
                        </Text>
                    )}
                </VStack>
            </HStack>
        </Box>
    );
};

export default HomeContent;
