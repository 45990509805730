import './App.css';
import "./Reset.css";
import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import Prova from './components/Prova/Prova';
import theme from './theme';
import EnvioRealizado from './components/AmbienteProva/EnvioRealizado';
import { useAutenticacao } from './components/hooks/useAutentication';
import Home from './components/AmbienteProva/Home/Home';
import Dashboard from './components/Dashboard/Dashboard';
import FAQ from './components/FAQ/Faq';
import { useEffect, useState } from 'react';

function App() {
  const { logado, estadoProva, isAdmin } = useAutenticacao();
  const navigate = useNavigate();
  const [isRouteOpen, setIsRouteOpen] = useState(false);

  useEffect(() => {
    const now = new Date();
    const startDate = new Date('2024-11-30T14:00:00'); // Horário de início
    const endDate = new Date('2024-11-30T17:00:00');  // Horário de término

    if (now >= startDate && now <= endDate) {
      setIsRouteOpen(true); // Rota aberta
    } else {
      setIsRouteOpen(false); // Rota fechada
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/home"
          element={
            logado ?
              (estadoProva === 'finalizada' ? <EnvioRealizado /> : <Home />)
              : <Navigate to="/" />
          }
        />
        <Route
          path="/prova"
          element={
            logado ?
              (estadoProva === 'finalizada' ? (
                <Navigate to="/home" />
              ) : (
                isRouteOpen ? (
                  <Prova />
                ) : (
                  <Navigate to="/home" state={{ message: 'A prova não está disponível no momento.' }} />
                )
              ))
              : <Navigate to="/" />
          }
        />
        {/* <Route
          path="/dashboard"
          element={
            logado && isAdmin ? <Dashboard /> : <Navigate to="/" />
          }
        /> */}
        {/* <Route path="/admin" element={<Dashboard />} /> */}
        <Route path="/faq"
          element={
            logado ?
              (estadoProva === 'finalizada' ? <EnvioRealizado /> : <FAQ />)
              : <Navigate to="/" />
          }
        />
        {/* <Route path="/faq" element={<FAQ />} /> */}

      </Routes>
    </ChakraProvider>
  );
}

export default App;
