import React from 'react';
import { Text } from '@chakra-ui/react';

const Enunciado = ({ questionNumber, statement }) => (
    <Text
        mb={4}
        fontSize={{ base: '18px', md: '20px', lg: '24px' }} // Define tamanhos de fonte diferentes para dispositivos pequenos e grandes
    >
        {`Questão ${questionNumber}: ${statement}`}
    </Text>
);

export default Enunciado;
