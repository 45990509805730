import React from 'react';
import { Box, Button, HStack, Heading, Image, Text, VStack } from '@chakra-ui/react';
import Fases from './Fases';

const ComoFunciona = () => {

    const PDFDownload = () => {
        window.open('/Regulamento_OECC_2024.pdf', '_blank');
    };

    return (
        <Box p={[4, 6, 8, 12]} marginLeft={{ base: '0', lg: '10%' }} marginRight={{ base: '0', lg: '10%' }}>
            <Heading 
                fontSize={'42px'} 
                color="#8DC42E" 
                textAlign="center"
                mb={4}
            >
                Como funciona?
            </Heading>
            <Text 
                width={['100%']}
                textAlign={["justify", "center"]}
                pl={[6, 0]}
                pr={[6, 0]}
                mb={6} 
                fontSize={['14px', '20px']}
                mx="auto"  // Centraliza o texto horizontalmente
            >
            A OECC avalia os conhecimentos dos competidores com base em padrões internacionais sobre a temática de consumo consciente, no tocante às ações de conscientização para o combate ao desperdício de água, de energia elétrica e de alimentos, bem como o descarte adequado de resíduos.              </Text>

            <HStack 
                gap={10} 
                justify="center" 
                flexDirection={{ base: 'column', lg: 'row' }} 
                alignItems="center"
            >
                <Image
                    src="/imagens/Webinar-cuate.svg"
                    w={{ base: '100%', md: '400px', lg: '420px' }}  // Ajusta a largura da imagem para diferentes tamanhos de tela
                    objectFit="contain"  // Garante que a imagem mantenha a proporção e ajuste dentro do contêiner
                />
                <VStack align={{ base: 'center', lg: 'start' }} spacing={4}>
                    <Fases />
                    <Button
                        onClick={PDFDownload}
                        color={'white'}
                        background={"#F5811E"}
                        marginTop={'2em'}
                        width={['80%', '20em']}
                        alignSelf={{ base: 'center', lg: 'start' }}  // Alinhamento do botão
                        _hover={{ bgGradient: "linear(to-r, orange.300, orange.500)" }}
                        borderRadius={'40px'}
                    >
                        Regulamento
                    </Button>
                </VStack>
            </HStack>
        </Box>
    );
};

export default ComoFunciona;
