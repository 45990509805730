import React from 'react';
import { Heading, HStack, Image, VStack, } from '@chakra-ui/react';

const Apoio = () => {
    return (
        <HStack gap={10} justify="left" mb={'2em'}>
            <VStack align="start" spacing={4}>
                <Heading fontSize={'20px'} alignSelf="flex-start">
                    Apoio
                </Heading>
                <Image src="\logos\lite-me-logo.svg" w={'12em'}></Image>
                <Image src='\logo-ifpb.png' w={'12em'}></Image>
            </VStack>
        </HStack>
    );
};

export default Apoio;
