import React from 'react';
import { Flex, RadioGroup, Radio, Box } from '@chakra-ui/react';

const gerarOpcaoDeLetra = (index) => {
    return String.fromCharCode(65 + index); // 65 é o código ASCII para 'A'
};

const Opcoes = ({ options, selectedOption, onOptionChange }) => (
    <RadioGroup onChange={onOptionChange} value={selectedOption}>
        <Flex direction="column">
            {options.map((option, index) => (
                <Radio
                    key={index}
                    value={option}
                    padding={'4px'}
                    _checked={{
                        borderColor: "blue.500", // Cor da borda quando selecionado
                        backgroundColor: "blue.50" // Cor de fundo quando selecionado
                    }}
                    _focus={{ boxShadow: "outline" }} // Sombra ao focar
                >
                    <Box fontSize={{ base: '16px', md: '18px', lg: '22px' }}
                        borderWidth="2px"          // Largura da borda
                        borderColor="gray.300"     // Cor da borda
                        borderRadius="md"          // Raio da borda
                        padding={'4px'}
                        w={['100%', '320px', '540px']}
                        shadow="sm"
                    >
                        {`${gerarOpcaoDeLetra(index)}. ${option}`}
                    </Box>
                </Radio>
            ))}
        </Flex>
    </RadioGroup>

);

export default Opcoes;
