import React, { useState, useEffect } from 'react';
import { Box, Button, VStack, Flex, Text, useDisclosure } from '@chakra-ui/react';
import Questao from './Questao/Questao';
import ConfirmarEnvio from './ConfirmarEnvio';
import useProva from '../hooks/useProva';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAutenticacao } from '../hooks/useAutentication';


const Prova = () => {
    
    const navigate = useNavigate();
    const { logout } = useAutenticacao();
    const email = localStorage.getItem('email');
    const tempo_prova = localStorage.getItem('tempo_prova');
    const [mensagemSucesso, setMensagemSucesso] = useState("");

    const {
        provaData: questoes = [],
        prova_id,
        loading,
        error,
        submitSuccess,
        submitProva,
        submitProvaFinal,
        atualizarResposta,
        respostas,
    } = useProva(email);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [timeLeft, setTimeLeft] = useState(60 * 60);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const getTimeColor = () => {
        if (timeLeft <= 10 * 60) {
            return 'red.500';
        }
        return '#1275bb';
    };

    useEffect(() => {

        const recuperarTempoDeProva = async () => {
            try {
                const response = await axios.get(`https://ocee.com.br/usuarios/tempo-prova/recuperar/?email=${email}`)
                const tempoRecuperado = response.data.tempo_prova;
    
                if(tempoRecuperado){
                    setTimeLeft(tempoRecuperado)
                }
            } catch (error) {
                console.error("Erro ao recuperar o tempo da prova", error.message);
            }
        }

        recuperarTempoDeProva(); 
        
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                const newTime = prevTime - 1;
                
                // Printando o timeLeft no console 
                atualizarTempoProvaRestante(newTime);
                console.log(newTime);
                return newTime;
            });
        }, 1000);
    
        return () => clearInterval(timer);
    }, []);
    // Envia pra o backend o tempo que atual
    const atualizarTempoProvaRestante = async (newTime) => {
        try {
            await axios.put('https://ocee.com.br/usuarios/tempo-prova/setar/', {
                email: email,
                tempo_prova: newTime // Enviando o newTime ao invés de timeLeft
            });
            console.log("Tempo salvo no backend:", newTime);
        } catch (error) {
            console.error("Erro ao salvar o tempo de prova: ", error.message);
        }
    }
    

    useEffect(() => {
        if (timeLeft <= 0) {
            alert('O tempo acabou!');
            setTimeLeft(0);
            handleConfirmSubmit();
            
        }
    }, [timeLeft]);

    useEffect(() => {
        const savedIndex = localStorage.getItem('currentQuestionIndex');
        if (savedIndex !== null) {
            setCurrentQuestionIndex(parseInt(savedIndex, 10));
        }
    }, []);

    const handleOptionChange = (value) => {
        setSelectedOption(value);
    };

    const handleNextQuestion = () => {
        if (selectedOption) {
            const questaoAtual = questoes[currentQuestionIndex];
            const questaoId = questaoAtual.questao_id;
            atualizarResposta(questaoId, selectedOption);

            // Avançar para a próxima questão não respondida
            let nextIndex = currentQuestionIndex + 1;
            while (nextIndex < questoes.length && respostas[questoes[nextIndex].questao_id]) {
                nextIndex++;
            }

            if (nextIndex >= questoes.length) {
                onOpen(); // Se todas as questões tiverem sido respondidas, abre o modal de confirmação
            } else {
                setCurrentQuestionIndex(nextIndex); // Avançar para a próxima questão não respondida
            }

            setSelectedOption(''); // Resetar a opção selecionada
        }
    };

    const handleConfirmSubmit = async () => {
        if (!email) {
            console.error("Email não definido ao tentar submeter a prova.");
            return;
        }

        const finalSubmission = {
            email: email,
            prova_id: prova_id,
            respostas: Object.keys(respostas).map((questao_id) => ({
                questao_id: parseInt(questao_id, 10),
                alternativa_marcada: respostas[questao_id],
            })),
            finalizada: true,
        };

        console.log("Dados a serem enviados:", finalSubmission);

        try {
            await submitProvaFinal(finalSubmission);
            onClose();
            localStorage.setItem("estado_prova", "finalizada");
            setMensagemSucesso("Submissão recebida com sucesso!");

            // Espera um tempo antes de deslogar e redirecionar
            setTimeout(() => {
                // Desloga o usuário
                logout();
            }, 2500);
        } catch (error) {
            console.error("Erro ao submeter a prova:", error.message);
            alert("Erro ao submeter a prova. Tente novamente.");
        }
    };

    // const handlePartialSubmit = async () => {
    //     if (!email) {
    //         console.error("Email não definido ao tentar submeter a prova.");
    //         return;
    //     }
    
    //     const respostasArray = Object.keys(respostas).map((questao_id) => ({
    //         questao_id: parseInt(questao_id, 10),
    //         alternativa_marcada: respostas[questao_id],
    //     }));
    
    //     console.log("Dados a serem enviados:", respostasArray);
    
    //     try {
    //         for (const resposta of respostasArray) {
    //             // Submete cada resposta individualmente
    //             await submitProva(resposta.questao_id, resposta.alternativa_marcada);
    //         }
    
    //         onClose();
    //         localStorage.setItem("estado_prova", "finalizada");
    //         setMensagemSucesso("Submissão parcial recebida com sucesso!");
    
    //         // Espera um tempo antes de deslogar e redirecionar
    //         setTimeout(() => {
    //             logout();
    //         }, 2500);
    //     } catch (error) {
    //         console.error("Erro ao submeter a prova:", error.message);
    //         alert("Erro ao submeter a prova. Tente novamente.");
    //     }
    // };

    const getRemainingQuestoesText = () => {
        const remainingQuestoes = questoes.length - currentQuestionIndex - 1;
        if (remainingQuestoes === 0) {
            return "Esta é a última questão";
        }
        return `Faltam ${remainingQuestoes} questões`;
    };

    return (
        <Box height={["130vh", "100vh"]} p={[8, 12]} bgColor={'#f0f0f0'} justifyContent="center" alignItems="center">
            <VStack h={'90%'}>
                <Flex justify="space-between" w="90%" direction={['column', 'row']} textAlign={'center'}>
                    <Text fontSize={["s", "xl"]} bg={getTimeColor()} p={'2'} color="white" borderRadius="10px" marginBottom={['2', '0']}>
                        Tempo restante: {formatTime(timeLeft)}
                    </Text>
                    <Text fontSize={["s", "xl"]} bg="#1275bb" p={'2'} color="white" borderRadius="10px">
                        {getRemainingQuestoesText()}
                    </Text>
                </Flex>

                {loading && <Text>Carregando...</Text>}
                {error && <Text color="red.500">Erro ao carregar questões: {error.message}</Text>}
                {mensagemSucesso && (<Box height={["130vh", "100vh"]} p={[8, 12]} bgColor={'#f0f0f0'} justifyContent="center" alignItems="center">
                        <Text color="green.500" fontSize="x1" fontWeight="bold">
                            {mensagemSucesso}
                        </Text>
                    </Box>
                )}
                
                {questoes.length > 0 ? (
                    <Questao
                        question={questoes[currentQuestionIndex]}
                        questionNumber={30 - (questoes.length - currentQuestionIndex) + 1} // Calcula a questão atual com base nas questões restantes
                        selectedOption={selectedOption}
                        handleOptionChange={handleOptionChange}
                    />
                ) : (
                    <Text>Carregando as questões...</Text>
                )}

                <Flex w="90%" justify="flex-end">
                    <Button
                        bg="#F5811E"
                        color="white"
                        borderRadius="40px"
                        _hover={{ bgGradient: "linear(to-r, orange.300, orange.500)" }}
                        onClick={handleNextQuestion}
                        isDisabled={!selectedOption || questoes.length === 0}
                    >
                        {currentQuestionIndex === questoes.length - 1 ? 'Finalizar Prova' : 'Próxima Questão'}
                    </Button>
                </Flex>
            </VStack>

            <ConfirmarEnvio isOpen={isOpen} onClose={onClose} onConfirm={handleConfirmSubmit} />
        </Box>
    );
};

export default Prova;
