import emailjs from '@emailjs/browser';

/**
 * Envia o formulário via email.
 * @param {Object} form - O formulário a ser enviado.
 * @param {Function} setIsSubmitting - Função para definir o estado de carregamento.
 * @param {Function} toast - Função para mostrar mensagens de feedback.
 */

export const sendEmail = (form, setIsSubmitting, toast) => {
    setIsSubmitting(true); // Define o estado de envio

    emailjs
        .sendForm('service_r4lw3zp', 'template_2mawwfw', form.current, {
            publicKey: 'psBZrjs4Yv5F8MVl7',
        })
        .then(
            () => {
                setIsSubmitting(false); // Reset o estado de envio
                toast({
                    title: "Mensagem enviada!",
                    description: "Sua mensagem foi enviada com sucesso.",
                    status: "success",
                    duration: 10000,
                    isClosable: true,
                    position: "top",
                    variant: "solid",
                    containerStyle: {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }
                });
                form.current.reset(); // Limpa o formulário após o envio
            },
            (error) => {
                setIsSubmitting(false); // Reset o estado de envio
                toast({
                    title: "Erro ao enviar mensagem",
                    description: `Houve um erro ao enviar sua mensagem: ${error.text}`,
                    status: "error",
                    duration: 10000,
                    isClosable: true,
                    position: "top",
                    variant: "solid",
                    containerStyle: {
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }
                });
            }
        );
};
