import './App.css';
import "./Reset.css";
import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import Prova from './components/Prova/Prova';
import theme from './theme';
import EnvioRealizado from './components/AmbienteProva/EnvioRealizado';
import { useAutenticacao } from './components/hooks/useAutentication';
import Home from './components/AmbienteProva/Home/Home';
import Dashboard from './components/Dashboard/Dashboard';
import FAQ from './components/FAQ/Faq';

function App() {
  const { logado, estadoProva, isAdmin } = useAutenticacao();

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/home"
          element={
            logado ?
              (estadoProva === 'finalizada' ? <EnvioRealizado /> : <Home />)
              : <Navigate to="/" />
          }
        />
        <Route
          path="/prova"
          element={
            logado ?
              (estadoProva === 'finalizada' ? <Navigate to="/home" /> : <Prova />)
              : <Navigate to="/" />
          }
        />
         {/* <Route
          path="/dashboard"
          element={
            logado && isAdmin ? <Dashboard /> : <Navigate to="/" />
          }
        /> */}
        <Route path="/admin" element={<Dashboard />} />
        <Route path="/faq"
        element={
          logado ?
          (estadoProva === 'finalizada'? <EnvioRealizado /> : <FAQ /> )
          : <Navigate to="/" />
        }
        />
        {/* <Route path="/faq" element={<FAQ />} /> */}

      </Routes>
    </ChakraProvider>
  );
}

export default App;
