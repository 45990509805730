import { useState, useEffect } from 'react';

const BASE_URL = 'https://ocee.com.br';

const useProva = (email) => {
    const [provaData, setProvaData] = useState([]);
    const [respostas, setRespostas] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(null);
    const [prova_id, setProvaId] = useState(null);

    useEffect(() => {
        const fetchProva = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${BASE_URL}/prova/recuperar-prova/?email=${email}`);
                const data = await response.json();

                console.log("Resposta do backend:", data);

                if (!data.questoes || data.questoes.length === 0) {
                    throw new Error("Nenhuma questão retornada");
                }

                const questoesNaoRespondidas = data.questoes.filter((questao) => {
                    // Filtra as questões que ainda não foram respondidas
                    return questao.resposta_marcada === null;
                });

                setProvaData(questoesNaoRespondidas);
                
                if (data.prova_id) {
                    setProvaId(data.prova_id);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        if (email) {
            fetchProva();
        }
    }, [email]);

    const submitProva = async (questao_id, alternativa_marcada) => {
        try {
            setLoading(true);
            const response = await fetch(`${BASE_URL}/prova/submeter-prova/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    prova_id,
                    respostas: [
                        {
                            questao_id: parseInt(questao_id, 10),
                            alternativa_marcada,
                        },
                    ],
                }),
            });

            if (!response.ok) {
                throw new Error('Falha ao submeter a prova');
            }

            const result = await response.json();
            console.log("Resultado da submissão:", result);
            setSubmitSuccess(true);
        } catch (error) {
            console.error("Erro ao submeter a prova:", error);
            setError(error);
            setSubmitSuccess(false);
        } finally {
            setLoading(false);
        }
    };

    const submitProvaFinal = async (finalSubmission) => {
        try {
            setLoading(true);
            const response = await fetch(`${BASE_URL}/prova/submeter-prova/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(finalSubmission),})

            if (!response.ok) {
                throw new Error('Falha ao submeter a prova');
            }

            const result = await response.json();
            console.log("Resultado da submissão:", result);
            setSubmitSuccess(true);
        } catch (error) {
            console.error("Erro ao submeter a prova:", error);
            setError(error);
            setSubmitSuccess(false);
        } finally {
            setLoading(false);
        }
    };

    const atualizarResposta = (questao_id, respostaSelecionada) => {
        setRespostas((prevRespostas) => ({
            ...prevRespostas,
            [questao_id]: respostaSelecionada,
        }));
        console.log(`Atualizando a resposta da questão ${questao_id} para ${respostaSelecionada}`);

        // Submete a resposta imediatamente após a seleção
        submitProva(questao_id, respostaSelecionada);
    };

    return {
        provaData,
        prova_id,
        respostas,
        loading,
        error,
        submitProva,
        submitProvaFinal,
        atualizarResposta,
    };
};

export default useProva;
