import React from 'react';
import { Text, Image, VStack, Button, Flex, Link } from '@chakra-ui/react';

const Chamada = () => {
    return (
        <Flex
            direction={{ base: 'column', lg: 'row' }}
            justifyContent='space-between'
            align='center'
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.3)"
            borderRadius={50}
            backgroundColor="#FFFCC3"
            p={6}
            margin={20}
            marginLeft={"10%"} marginRight={"10%"}
            alignItems={'center'}

        >
            <Image 
loading="lazy"                src="/imagens/Email campaign-cuate.svg" // Ajuste o caminho da imagem conforme necessário
                w={{ base: '50%', md: '30%', lg: '15%' }}
                alignSelf={{ base: 'center', lg: 'flex-start' }}
            />
            <VStack
                align="center"
                spacing={4}
                fontWeight="bold"
                w={{ base: '90%', lg: '50em' }}
                mt={{ base: 4, lg: 0 }}
                mb={{ base: 4, lg: 0 }}
                textAlign={{ base: 'center', md: 'left' }}
                justify={'space-between'}
            >
                <Text fontSize={{ base: '14px', md: '16px', lg: '18px' }} align={'center'}>
                    Gostaria de participar da Olimpíada Educacional de Consumo Consciente? Inscreva-se em nosso formulário e embarque conosco nessa jornada de conscientização!
                </Text>
                <Text fontSize={{ base: '12px', md: '14px', lg: '16px' }} align={'center'}>
                   Período de inscrições: 01 de setembro a 14 de novembro de 2024
                </Text>

                <Link href="https://forms.gle/TtLa5amSaEC6NfuJA" isExternal>
                <Button
                    bg="#F5811E"
                    color="white"
                    bgGradient="linear(to-r, #FFA85D, #F5811E)"
                    borderRadius="40px"
                    px={8}
                    py={4}
                    fontSize={['12px', '16px']}
                    width={['80%', '20em']}
                    _hover={{ bgGradient: "linear(to-r, orange.300, orange.500)" }}
                >
                    Inscreva-se agora!
                </Button>
                </Link>
            </VStack>
            <Image 
loading="lazy"                src="/imagens/Mobile Marketing-cuate.svg" // Ajuste o caminho da imagem conforme necessário
                w={{ base: '0%', md: '30%', lg: '15%' }}
                
                alignSelf={{ base: 'center', lg: 'flex-start' }}
            />
        </Flex>
    );
};

export default Chamada;
