import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Input,
  Stack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Spinner,
} from '@chakra-ui/react';
import Menu from '../Menu/Menu';

const BASE_URL = "https://ocee.com.br";
const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [action, setAction] = useState(null);
  const [file, setFile] = useState(null);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleUpload = async () => {
    if (!file) {
      toast({
        title: "Erro",
        description: "Selecione um arquivo para upload.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("json_file", file);
      formData.append("file_type", "json_file");

      const route = action === 1 ? `${BASE_URL}/usuarios/importar-dados-usuarios/` : `${BASE_URL}/prova/importar-dados-questoes/`;
      
      await axios.post(route, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast({
        title: "Sucesso",
        description: "Arquivo enviado com sucesso!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Ocorreu um erro ao enviar o arquivo.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleDownload = async () => {
    setLoading(true);
    
    try {
      let route = "";
      let fileName = "";
      let fileType = "";

      switch (action) {
        case 3:
          route = `${BASE_URL}/relatorios/exportar-submissoes`;
          fileName = "relatorio_submissoes.csv";
          fileType = "csv";
          break;
        case 4:
          route = `${BASE_URL}/relatorios/imprimir-grafico-questoes`;
          fileName = "relatorio_estatistico_questoes.zip";
          fileType = "zip";
          break;
        case 5:
          if (!email) {
            toast({
              title: "Erro",
              description: "Informe o email do usuário.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            setLoading(false);
            return;
          }
          route = `${BASE_URL}/relatorios/gerar-csv-submissao/?email=${email}`;
          fileName = "submissao_usuario.csv";
          fileType = "csv";
          break;
        default:
          return;
      }

      const response = await axios.get(route, { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      toast({
        title: "Sucesso",
        description: "Download iniciado.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erro",
        description: "Ocorreu um erro ao realizar o download.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const openModal = (selectedAction) => {
    setAction(selectedAction);
    setFile(null);
    setEmail('');
    onOpen();
  };

  return (
    <Box bgColor={'#f0f0f0'} minH="100vh" p={0.5}>
      <Menu />
      <Box
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="lg"
        width="100%"
        maxW="500px"
        mx="auto" 
        mt="20vh" 
      >
        <Stack spacing={4} align="center" width="100%">
          <Button bg={"#F5811E"} color={"white"} width="100%" onClick={() => openModal(1)}>Importar dados usuários</Button>
          <Button bg={"#F5811E"} color={"white"} width="100%" onClick={() => openModal(2)}>Importar dados questões</Button>
          <Button bg={"#f7a054"} color={"white"} width="100%" onClick={() => openModal(3)}>Exportar relatório geral</Button>
          <Button bg={"#f7a054"} color={"white"} width="100%" onClick={() => openModal(4)}>Exportar relatório estatístico</Button>
          <Button bg={"#f7a054"} color={"white"} width="100%" onClick={() => openModal(5)}>Exportar prova do usuário</Button>
        </Stack>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {action === 1 && "Importar dados usuários"}
              {action === 2 && "Importar dados questões"}
              {action === 3 && "Exportar relatório geral"}
              {action === 4 && "Exportar relatório estatístico"}
              {action === 5 && "Exportar prova do usuário"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {loading ? (
                <Spinner size="lg" />
              ) : (
                <>
                  {(action === 1 || action === 2) && (
                    <FormControl>
                      <FormLabel>Selecione o arquivo para upload</FormLabel>
                      <Input type="file" onChange={(e) => setFile(e.target.files[0])} />
                    </FormControl>
                  )}
                  {action === 5 && (
                    <FormControl mt={4}>
                      <FormLabel>Email do usuário</FormLabel>
                      <Input
                        placeholder="exemplo@dominio.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {!loading && (
                <>
                  {action === 1 || action === 2 ? (
                    <Button bg={"#F5811E"} color={"white"} onClick={handleUpload} mr={3}>
                      Upload
                    </Button>
                  ) : (
                    <Button bg={"#F5811E"} color={"white"} onClick={handleDownload} mr={3}>
                      Download
                    </Button>
                  )}
                  <Button variant="ghost" onClick={onClose}>Cancelar</Button>
                </>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}

export default Dashboard;
