import React, { useState } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import Slide from './Slide';

const text = [
    { "caption": "Ar condicionado:\n1. Mantenha os filtros limpos;\n2. Mantenha portas e janelas fechadas;\n3. Ajuste a temperatura para 23 °C ou superior;\n4. Mantenha a saída de ar livre para melhor circulação do ar;\n5. Ao deixar o ambiente, desligue o aparelho." },
    { "caption": "Desligue aparelhos em standby:\nAparelhos em modo standby ainda consomem energia. Desconecte dispositivos eletrônicos quando não estiverem em uso para evitar o consumo desnecessário." },
    { "caption": "Eficiência energética:\nSignifica produzir a mesma quantidade de trabalho com menos energia, permitindo que os aparelhos operem com a mesma capacidade, mas com consumo menor de energia elétrica." },
    { "caption": "Eletrônicos:\n1. Desligue a TV quando não estiver assistindo;\n2. Use a função timer quando assistir TV à noite;\n3. No modo stand by da TV há consumo de energia;\n4. Use o modo de suspensão para se ausentar por um curto período do notebook ou computador;\n5. Use o modo de hibernação quando não for usar o computador por um longo período." },
    { "caption": "Equipamentos eficientes:\nNa hora de comprar um aparelho eletrônico, escolha sempre os que vêm com o selo Procel de economia de energia. O selo indica quais os aparelhos mais eficientes no consumo. A escala varia de 'A' a 'G', sendo mais eficientes os que têm a classificação 'A'." },
    { "caption": "Ferro de Passar Roupas:\n1. Planeje um dia para passar todas as roupas de uma só vez;\n2. Use a temperatura indicada para o tipo de tecido, iniciando pelas roupas delicadas;\n3. Mantenha a base do ferro limpa;\n4. Desligue e aproveite a temperatura para passar roupas leves;\n5. Ao dobrar e secar, evite deformações na peça, isso reduz o tempo de passagem." },
    { "caption": "Geladeira:\n1. Regule o termostato de acordo com a estação do ano;\n2. Antes de abrir a geladeira certifique-se do que precisa;\n3. Não guarde alimentos quentes;\n4. Descongele e limpe com frequência;\n5. Não seque roupas na grade da geladeira." },
    { "caption": "Hábitos inteligentes:\nUtilizar os equipamentos elétricos de forma equilibrada, para evitar o consumo excessivo de energia." },
    { "caption": "Iluminação:\n1. Durante o dia, use a luz natural;\n2. Escolha cores claras para as paredes;\n3. Ao sair do ambiente, desligue as luzes;\n4. Limpe a luminária para melhorar a distribuição de luz;\n5. Substitua as lâmpadas queimadas por led." },
    { "caption": "Iluminação eficiente:\nTroque lâmpadas incandescentes por lâmpadas LED. Elas consomem até 80% menos energia e têm uma vida útil muito mais longa, resultando em economia e menos resíduos." },
    { "caption": "Liquidificador:\n1. Não sobrecarregue o liquidificador além da sua capacidade;\n2. Corte os alimentos em pequenos pedaços, isso reduz o tempo de funcionamento;\n3. Se possível, junte todos os ingredientes e triture tudo de uma única vez." },
    { "caption": "Manutenção regular:\nRealize manutenção regular em seus aparelhos elétricos e sistemas de aquecimento/refrigeração. Isso garante que eles funcionem com eficiência e ajudam a evitar o desperdício de energia." },
    { "caption": "Micro-ondas:\n1. Evite o uso dispensável do micro-ondas, descongele o alimento naturalmente;\n2. Adicione uma pequena quantidade de água no alimento a ser aquecido;\n3. Use recipientes que não absorvem calor e com tampa, isso retém o vapor no alimento;\n4. Ajuste o tempo e a potência adequados ao tipo de alimento." },
    { "caption": "Projetos inteligentes:\nNo projeto de uma casa já podemos utilizar soluções criativas que vão nos ajudar a economizar energia, como ambientes que aproveitam melhor a luz natural. Nossos hábitos de consumo também podem ajudar na economia, com simples mudanças como manter a geladeira aberta por menos tempo." },
    { "caption": "Reduza o desperdício:\nQuanto maior o desperdício de energia, maior o valor da 'conta de luz' no final do mês. Ao usar a energia elétrica de forma racional, poupamos nosso dinheiro e ajudamos a economizar recursos da natureza usados na sua produção." },
    { "caption": "Uso consciente do ar-condicionado:\nMantenha o termostato do ar-condicionado em uma temperatura moderada, entre 22°C e 24°C. Isso pode reduzir significativamente o consumo de energia e aumentar a durabilidade do aparelho." },
    { "caption": "Uso da Máquina de Lavar:\n1. Utilize o ciclo de lavagem mais adequado ao tipo de roupa;\n2. Junte roupas para uma lavagem com a capacidade total da máquina;\n3. Mantenha a máquina sempre limpa." },
    { "caption": "Uso eficiente da água:\nInstale aeradores em torneiras e chuveiros para reduzir o consumo de água. Menos água quente significa menor consumo de energia para aquecimento." },
    { "caption": "Utilize a energia de forma inteligente:\nAproveite a luz natural durante o dia para reduzir a necessidade de iluminação artificial. Abra cortinas e persianas para iluminar os ambientes com luz natural." },
    { "caption": "Ventilador:\n1. Ajuste a velocidade de acordo com a necessidade;\n2. Desligue quando não estiver utilizando;\n3. Faça a limpeza das hélices para facilitar a circulação de ar." }
]

const Carrossel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slidesPerPage = useBreakpointValue({ base: 1, md: 3, lg: 4 });
    const totalSlides = text.length;
    const totalPages = Math.ceil(totalSlides / slidesPerPage);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalPages);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + totalPages) % totalPages);
    };

    const startIndex = currentIndex * slidesPerPage;
    const currentSlides = text.slice(startIndex, startIndex + slidesPerPage);

    return (
        <Flex direction="column" align="center" w="100%" maxW="1200px" m="0 auto">
            <Flex
                overflowX="hidden"
                w="100%"
                px={4}
                py={2}
                position="relative"
            >
                <Flex
                    w={`${totalSlides * (100 / slidesPerPage)}%`}
                    flexDirection="row"
                    transition="transform 0.7s ease"
                    transform={`translateX(-${(currentIndex * 100) / totalPages}%)`}
                >
                    {text.map((image, index) => (
                        <Box
                            key={index}
                            w={`${100 / slidesPerPage}%`} // Cada slide ocupa uma fração do container
                            p={2}
                            boxSizing="border-box"
                        >
                            <Slide image={image} />
                        </Box>
                    ))}
                </Flex>
            </Flex>
            <Flex mt={4}>
                <ChevronLeftIcon onClick={prevSlide} fontSize={'26px'} color="#F5811E" cursor="pointer" />
                <ChevronRightIcon onClick={nextSlide} fontSize={'26px'} color="#F5811E" cursor="pointer" />
            </Flex>
        </Flex>
    );
};

export default Carrossel;
