import React from "react";
import { useDisclosure } from "@chakra-ui/react";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import { useAutenticacao } from "../../hooks/useAutentication";
import { useNavigate } from "react-router-dom";

const Menu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { logout } = useAutenticacao();
    const navigate = useNavigate();

    const handleLogout = () => {
        console.log("Tentando fazer logout...");
        logout();
        navigate('/'); // Redireciona para a página inicial
    };

    return (
        <>
            <MobileMenu onClick={handleLogout} />
            <DesktopMenu onOpen={onOpen} onClick={handleLogout} />
        </>
    );
};

export default Menu;
