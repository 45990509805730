import React from 'react';
import { Box, Flex, Image, VStack } from '@chakra-ui/react';
import Localizacao from './Localizacao';
import Realizacao from './Realizacao';
import Equipe from './Equipe';
import Apoio from './Apoio';
import Patrocinio from './Patrocinio';


const Footer = () => {
    return (
        <Box w="100%" h="110%"p={{ base: 6, md: 10, lg: 8 }}  backgroundColor="#F0F0F0">
            <Flex gap={20} align="flex-start" justify={'center'} direction={{ base: 'column', lg: 'row' }}>
                <VStack align={'left'}>
                    <Image src="\logos\oecc_horizontal.svg" w={'12em'} mb={'2em'}></Image>
                    <Realizacao />
                    <Patrocinio/>
                    <Apoio/>
                </VStack>
                <Equipe />
                <Localizacao/>
            </Flex>
        </Box>
    );
};

export default Footer;
