import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useAutenticacao = () => {
    const navigate = useNavigate();

    const [logado, setLogado] = useState(() => localStorage.getItem("logado") === "true");
    const [estadoProva, setEstadoProva] = useState(localStorage.getItem("estado_prova") || "");
    const [nomeUsuario, setNomeUsuario] = useState(localStorage.getItem("nome") || "");
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem("is_admin") || "false");

    const login = (estadoProva, nome, is_admin) => {
        localStorage.setItem("logado", "true");
        localStorage.setItem("estado_prova", estadoProva);
        localStorage.setItem("nome", nome);
        localStorage.setItem("is_admin", is_admin);

        console.log("Estado da prova recebido do backend:", estadoProva);

        setLogado(true);
        setEstadoProva(estadoProva);
        setNomeUsuario(nomeUsuario);
        setIsAdmin(is_admin);

        window.location.href = '/home';
    };

    const logout = () => {
        localStorage.removeItem("logado");
        localStorage.removeItem("estado_prova");
        localStorage.removeItem("nome");
        setLogado(false);
        setEstadoProva("");
        setNomeUsuario("");
        setIsAdmin(false);

        navigate('/');
    };

    return {
        logado,
        estadoProva,
        nomeUsuario,
        isAdmin,
        login,
        logout
    };
};

export default useAutenticacao
