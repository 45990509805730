import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, Image, Link, HStack, VStack, Grid, GridItem } from '@chakra-ui/react';

const LeiaMais = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent maxW={{ base: "90%", sm: "80%", md: "80%", lg: "1000px" }} p='4'>
                <ModalHeader fontSize={{ base: "18px", md: "22px", lg: "26px" }} textAlign="center">Sobre a OECC</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Grid
                        templateColumns={{ base: "1fr", md: "1fr", lg: "1fr 1fr" }}
                        gap={6}
                        alignItems="center"
                        mb={4}
                        templateRows={{ base: "auto auto", md: "auto auto" }}
                    >
                        <GridItem 
                            display="flex" 
                            justifyContent="center" 
                            alignItems="center" 
                            order={{ base: 2, md: 1 }}
                        >
                            <VStack alignItems="center">
                                <HStack spacing={4} wrap="wrap" justify="center">
                                    {[
                                        { id: 2, label: "Fome Zero" },
                                        { id: 3, label: "Saúde e Bem-Estar" },
                                        { id: 4, label: "Educação de Qualidade" },
                                        { id: 6, label: "Água Potável e Saneamento" },
                                        { id: 7, label: "Energia Acessível e Limpa" },
                                        { id: 11, label: "Cidades e Comunidades Sustentáveis" },
                                        { id: 12, label: "Consumo e Produção Responsáveis" },
                                        { id: 13, label: "Ação Contra a Mudança Global do Clima" },
                                        { id: 15, label: "Vida Terrestre" }
                                    ].map(ods => (
                                        <Link
                                            key={ods.id}
                                            href={`https://brasil.un.org/pt-br/sdgs/${ods.id}`}
                                            isExternal
                                        >
                                            <Image 
loading="lazy"                                                src={`/ods/SDG-${ods.id}.svg`} // Caminho para as imagens na pasta 'public/ods'
                                                alt={ods.label}
                                                boxSize={{ base: "58px", md: "94px", lg: "120px" }} // Tamanho ajustado para maior compatibilidade com o layout
                                            />
                                        </Link>
                                    ))}
                                </HStack>
                            </VStack>
                        </GridItem>
                        <GridItem 
                            display="flex" 
                            justifyContent="center" 
                            alignItems="center" 
                            order={{ base: 2, md: 2 }}
                        >
                            <Text fontSize={{ base: "14px", md: "16px" }} align="justify">
                            A Olimpíada Educacional do Consumo Consciente (OECC),  uma competição técnica/científica , é um projeto organizado pela UFCG, com o apoio do IFPB e com o patrocínio da CAGEPA . A OECC tem como foco a apresentação de material instrucional e atividades acadêmicas sobre o consumo consciente, tanto para a redução dos consumos de água, energia elétrica e alimentos, quanto ao impacto ambiental do consumismo. A OECC tem como público-alvo estudantes do 8º e 9º ano do ensino fundamental das escolas públicas e privadas do Estado da Paraíba.  
                            A OECC está em sintonia com os Objetivos de Desenvolvimento Sustentável (ODS) que são um apelo global da Organização das Nações Unidas (ONU), no tocante às ações para acabar com a pobreza, proteger o meio ambiente e o clima e garantir que as pessoas, em todos os lugares, possam desfrutar de paz e de prosperidade.
                            </Text>
                        </GridItem>
                    </Grid>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default LeiaMais;
