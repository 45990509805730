import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Opcoes from './Opcoes';
import MidiaQuestao from './MidiaQuestao';
import Enunciado from './Enunciado';

const Questao = ({ question, questionNumber, selectedOption, handleOptionChange }) => {

    const options = [
        question.alt_a,
        question.alt_b,
        question.alt_c,
        question.alt_d,
    ];

    const media = question.imagem || question.video;

    return (
        <Box
            p={4}
            shadow="md"
            borderRadius="md"
            bgColor={'white'}
            alignSelf={'center'}
            height={'auto'}
            width={'90%'}
        >
            <Flex direction="column">
                <Enunciado
                    questionNumber={questionNumber}
                    statement={question.enunciado}

                />
                {media ? (
                    <Flex direction={{ base: "column", lg: "row" }} gap={4} align={{ base: "start", lg: "center" }}>
                        <Box flex="1">
                            <MidiaQuestao media={media} />
                        </Box>
                        <Flex direction="column" flex="1">
                            <Opcoes
                                options={options}
                                selectedOption={selectedOption}
                                onOptionChange={handleOptionChange}
                            />
                        </Flex>
                    </Flex>
                ) : (
                    <Flex direction="column">
                        <Opcoes
                            options={options}
                            selectedOption={selectedOption}
                            onOptionChange={handleOptionChange}
                        />
                    </Flex>
                )}
            </Flex>
        </Box>
    );
};

export default Questao;
