import React from 'react';
import { Box, Image, AspectRatio } from '@chakra-ui/react';
import YouTube from 'react-youtube';

const isYouTubeVideo = (url) => {
    return url && (url.includes('youtube.com') || url.includes('youtu.be'));
};

const getYouTubeId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
};

const BASE_URL = "https://ocee.com.br";

const MidiaQuestao = ({ media }) => {
    if (!media) {
        return null;
    }

    const mediaURL = media.startsWith('/midias') ? `${BASE_URL}${media}` : media;

    if (isYouTubeVideo(media)) {
        return (
            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                <AspectRatio ratio={16 / 9} width={{ base: '100%', lg: '600px' }} height="100%">
                    <YouTube
                        videoId={getYouTubeId(media)}
                        opts={{
                            playerVars: {
                                width: '100%',
                                height: '100%',
                                autoplay: 0,
                                controls: 1,
                                modestbranding: 1,
                                rel: 0,
                            },
                        }}
                    />
                </AspectRatio>
            </Box>
        );
    } else {
        return (
            <Box width={{ base: '100%', lg: 'auto' }} display="flex" justifyContent="center" alignItems="center">
                <Image src={mediaURL} alt="Mídia da questão" maxH="100%" maxW="100%" objectFit="contain" />
            </Box>
        );
    }
};

export default MidiaQuestao;
