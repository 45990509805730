import React from 'react';
import { Box, Heading, HStack, Image } from '@chakra-ui/react';
import ConsumoConsciente from './ConsumoConsciente';


const MaterialDeApoio = () => {
    
    return (
        <Box id="materialapoio" marginLeft={"10%"} marginRight={"10%"}>
            <HStack gap={10} justify="center" align="center" mb={10} flexDirection={{ base: 'column', lg: 'row' }}>
                <Image
                    src="\imagens\Learning-cuate.svg"
                    w={{ base: '100%', md: '450px', lg: '550px' }}  // Ajuste o tamanho da imagem para ocupar a largura total em telas pequenas e um tamanho fixo em telas maiores
                />
                <Heading fontSize={['48px', '64px']} color="#323C33" align={'center'}>
                    Material de apoio
                </Heading>
            </HStack>
            <ConsumoConsciente />
            
        </Box>
    );
};

export default MaterialDeApoio;