import React, { useState } from "react";
import {
    Box,
    Button,
    Link,
    ButtonGroup,
    useDisclosure,
    Image,
} from "@chakra-ui/react";
import MudarSenhaModal from "./MudarSenha"; // Importa o modal
import { useNavigate } from 'react-router-dom';
import { useAutenticacao } from "../../hooks/useAutentication";

const DesktopMenu = ({ onClick }) => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activeModal, setActiveModal] = useState(null);
    const { isAdmin } = useAutenticacao();

    const openModal = (modalName) => {
        setActiveModal(modalName);
        onOpen();
    };

    return (
        <>
            <Box
                bg="rgb(141, 196, 46)"
                position="fixed"
                width="100%"
                height="50px"
                padding="0 5%"
                display={{ base: "none", lg: "flex" }} // oculta em telas menores que lg
                justifyContent="space-between"
                alignItems="center"
                zIndex="8"
            >
                <Link href="#oecc">
                    <Image
                        loading="lazy"
                        src="\logos\brancooecc_horizontal 1.svg"
                        w="180px"
                        align="center"
                    />
                </Link>
                <ButtonGroup variant="outline" spacing="6" ml="auto">
                    {/*
                {isAdmin && (
                    <Button
                        variant="ghost"
                        color="white"
                        _hover={{
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                        }}
                        borderRadius={"40px"}
                        onClick={() => navigate('/dashboard')}
                    >
                        ADMIN
                    </Button>
                )}
                */}
                    <Button
                        variant="ghost"
                        color="white"
                        _hover={{
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                        }}
                        borderRadius={"40px"}
                    >
                        <Link href="/faq"
                            _hover={{ textDecoration: "none" }}
                        >
                            DÚVIDAS?
                        </Link>
                    </Button>
                    <Button
                        variant="ghost"
                        color="white"
                        _hover={{
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                        }}
                        borderRadius={"40px"}
                        onClick={() => openModal("mudarSenha")}
                    >
                        MUDAR SENHA
                    </Button>
                    <Button
                        variant="ghost"
                        bg={"#F5811E"}
                        color={"white"}
                        _hover={{
                            bgGradient: "linear(to-r, orange.300, orange.500)",
                        }}
                        borderRadius={"40px"}
                        onClick={onClick}
                    >
                        SAIR
                    </Button>
                </ButtonGroup>
            </Box>

            {activeModal === "mudarSenha" && (
                <MudarSenhaModal isOpen={isOpen} onClose={onClose} />
            )}
        </>
    );
};

export default DesktopMenu;