import React, { useEffect, useState } from 'react';
import { Box, Text, HStack, Button, VStack, Image, useDisclosure, keyframes, Flex } from '@chakra-ui/react';
import LeiaMais from './LeiaMais';
import Confetti from 'react-confetti';

// Define a animação de balanço
const shake = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(5deg); }
  100% { transform: rotate(0deg); }
`;

const Oecc = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showConfetti, setShowConfetti] = useState(true);

    const PDFDownload = () => {
        window.open('/Resultado_Final_OECC_2024.pdf', '_blank');
    };

    // const OrientacoesSegundFase = () => {
    //     window.open('https://docs.google.com/document/d/1qfgpkij8cjQsaC7nygP06Z0zpi_i8Sgs0lYlPC56sNg/edit?tab=t.0');
    // };

    useEffect(() => {
        const timer = setTimeout(() => setShowConfetti(false), 7000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Box id="oecc" p={[4, 12]} marginLeft={{ base: '0', lg: '10%' }} marginRight={{ base: '0', lg: '10%' }}>
            {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}

            <HStack gap={10} justify="center" flexDirection={{ base: 'column', lg: 'row' }} alignItems="center" marginTop={20}>
                <Image
                    loading="lazy" src="\imagens\GLOBO.svg"
                    w={{ base: '70%', md: '500px', lg: '440px' }}
                    mb={{ base: '4', md: '0' }}
                />
                <VStack align="start" spacing={5} flex="1" pl={[6, 0]} pr={[6, 0]}>
                    <Text fontSize={['14px', '16px', '20px']} align="justify">
                        A Olimpíada Educacional do Consumo Consciente (OECC), uma competição técnica/científica, é um projeto organizado pela UFCG, com o apoio do IFPB e com o patrocínio da CAGEPA. A OECC tem como foco a apresentação de material instrucional e atividades acadêmicas sobre o consumo consciente, tanto para a redução dos consumos de água, energia elétrica e alimentos, quanto ao impacto ambiental do consumismo. A OECC tem como público-alvo estudantes do 8º e 9º ano do ensino fundamental das escolas públicas e privadas do Estado da Paraíba.
                    </Text>
                    <Flex spacing={4} mt={[0, 4]} flexDirection={['column', 'row']} align={['center', 'space-between']}>
                        <Button
                            onClick={PDFDownload}
                            color={'white'}
                            background={"#1275bb"}
                            width={['100%', '20em']}
                            alignSelf={['center', 'start']}
                            _hover={{ bgGradient: "linear(to-r, blue.300, blue.500)" }}
                            borderRadius={'40px'}
                            mb={[4, 0]}
                            mr={[0, 4]}
                            animation={`${shake} 0.5s ease-in-out 12`} // Adiciona a animação de balanço
                          
                        >
                            Resultado Final OECC 2024
                        </Button>
                        <Button
                            color={'white'}
                            background={"#F5811E"}
                            width={['100%', '20em']}
                            alignSelf={['center', 'start']}
                            _hover={{ bgGradient: "linear(to-r, orange.300, orange.500)" }}
                            borderRadius={'40px'}
                            mb={[10, 0]}
                            onClick={onOpen}
                        >
                            Leia Mais
                        </Button>
                    </Flex>
                </VStack>
            </HStack>
            <LeiaMais isOpen={isOpen} onClose={onClose} />
        </Box>
    );
};

export default Oecc;
