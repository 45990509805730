import { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Input,
    Text,
    IconButton,
    Image,
    useToast
} from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import { handlePerdeuSenha } from './authHandlers'; // Importe a função de recuperação de senha

const RecuperarSenha = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const toast = useToast(); // Hook para mostrar toasts

    const onResetPasswordClick = async () => {
        setError(null); // Limpar qualquer erro anterior
        const success = await handlePerdeuSenha(email, setError, onClose);
        
        if (success) {
            toast({
                title: "Solicitação enviada",
                description: "Se o e-mail estiver cadastrado, você receberá uma nova senha em breve.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Erro",
                description: "Não foi possível processar sua solicitação. Tente novamente mais tarde.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay bg="rgba(0, 0, 0, 0.8)" backdropFilter="blur(10px)" />
            <ModalContent bg="white">
                <IconButton
                    aria-label="Fechar"
                    icon={<MdClose />}
                    onClick={onClose}
                    position="absolute"
                    top="1rem"
                    right="1rem"
                    background="transparent"
                    size="sm"
                />
                <ModalHeader display="flex" justifyContent="space-between" alignItems="center">
                    <Image
                        src='/logos/oecc_horizontal.svg'
                        alt="Logo"
                        width={{ base: '80%', sm: '60%', md: '40%' }}
                        maxWidth="300px"
                        mx="auto"
                        mt={2}
                    />
                </ModalHeader>

                <ModalBody fontWeight="bold">
                    <Text>Insira seu e-mail para receber sua nova senha</Text>
                    <Input
                        type='email'
                        placeholder="Insira seu e-mail"
                        mb={4}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    {error && <Text color={"red.500"} mt={2}>{error}</Text>}
                </ModalBody>

                <ModalFooter justifyContent="center">
                    <Button bg="#f68733" color="white" onClick={onResetPasswordClick}>
                        Enviar nova senha
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default RecuperarSenha;
