import React from 'react';
import { Box, Text, Heading, Image } from '@chakra-ui/react';
import Menu from './Menu/Menu';
import { useAutenticacao } from '../hooks/useAutentication';


const EnvioRealizado = () => {

    const { nomeUsuario } = useAutenticacao();

    return (
        <>
            <Menu />
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh" p={[8, 12]} bgColor={'#f0f0f0'} flexDirection={'column'}>
                <Image
                    loading="lazy" src="/imagens/Happy student-cuate.svg"
                    w={{ base: '100%', md: '600px', lg: '450px' }}
                    mb={{ base: '4', md: '0' }}
                />
                <Heading fontSize={['38px', '50px']} color="#323C33" marginBottom={'10'} textAlign={'center'}>
                    Envio realizado com sucesso!
                </Heading>
                <Text ml={[0, 12]} mr={[0, 12]} align={['justify', 'center']} fontSize={['16px', '24px']} >
                    Parabéns {nomeUsuario}! Sua prova foi recebida em nosso sistema. Agradecemos por sua participação!
                </Text>
            </Box>
        </>
    );
}

export default EnvioRealizado;
